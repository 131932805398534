// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ReactPlayer from 'react-player';

// Styles
import styles from './styles.module.css';

const Story = ({ heading, body, videoUrl, image, imageAltText }) => (
  <div className="story">
    <h2 className={classnames('alt theme-color-primary', styles.textCenter)}>{heading}</h2>

    {videoUrl && (
      <div className="react-player__wrapper">
        <ReactPlayer className="react-player" url={videoUrl} width="100%" height="100%" />
      </div>
    )}

    {!videoUrl && image && (
      <div>
        <img src={image} alt={imageAltText} />
      </div>
    )}

    {body && <div className={styles.body} dangerouslySetInnerHTML={{ __html: body }} />}
  </div>
);

Story.propTypes = {
  heading: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  videoUrl: PropTypes.string,
  image: PropTypes.string,
  imageAltText: PropTypes.string
};

Story.defaultProps = {
  videoUrl: '',
  image: '',
  imageAltText: ''
};

export default Story;
