// Libraries
import React from 'react';

// Components
import LayoutContainer from 'marketing/containers/LayoutContainer';
import TitleBlock from 'marketing/components/TitleBlock';
import Hero from 'marketing/components/Hero';
import Story from 'marketing/components/Story';

export default data => {
  const {
    heroSubheading,
    heroHeading,
    heroImage,
    heroButtonText,
    contentHeading,
    contentSubheading,
    contentIntroduction,
    contentBody,
    contentQuote,
    actionButtonText,
    actionButtonUrl,
    stories
  } = data.pageContext.data;

  return (
    <LayoutContainer includeOrderTicketsCta includeWinnersLink={data.pageContext.winnersLink}>
      <Hero
        subHeading={heroSubheading}
        mainHeading={heroHeading}
        backgroundUrl={heroImage}
        buttonText={heroButtonText}
      />
      <div className="l-container">
        <div className="l-col-offset-2 l-col-8">
          <TitleBlock mainHeading={contentHeading} subHeading={contentSubheading} />
          <div className="wysiwyg l-margin-top">
            {contentIntroduction && (
              <div
                className="t-intro t-center theme-color-primary l-margin"
                dangerouslySetInnerHTML={{ __html: contentIntroduction }}
              />
            )}

            {contentBody && <div dangerouslySetInnerHTML={{ __html: contentBody }} />}

            {contentQuote && (
              <div
                className="t-promoted t-center theme-color-primary"
                dangerouslySetInnerHTML={{ __html: contentQuote }}
              />
            )}

            {stories &&
              stories.length > 0 &&
              stories.map(story => <Story key={story.heading} {...story} />)}

            {actionButtonUrl && actionButtonText && (
              <p className="t-center">
                <a
                  href={actionButtonUrl}
                  className="button button--secondary theme-background-secondary l-margin t-center"
                >
                  {actionButtonText}
                </a>
              </p>
            )}
          </div>
        </div>
      </div>
    </LayoutContainer>
  );
};
